import { Box, Step, StepIconProps, StepLabel, Stepper, styled, Tab, Tabs, Button, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useDispatch, useSelector } from 'react-redux';
import { siteSelector } from '../store/selectors/siteSelector';
import { adminSelector, dataSelector, patientSelector } from '../store/selectors';
import { AdminRole, Patient, PatientGroup, PatientStatus, ResponseKitData } from '../types/types';
import { useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Drafts, LocalShipping, Mail, Pending, VolunteerActivism } from '@mui/icons-material';
import { setKitData, setPatients } from '../store/slices';
import { useGetAnalyseKitDataMutation, useGetPatientsMutation } from '../store/api';
import { useAppContext } from '../components/authentication/account';
import HandleLoadingError from '../components/handle-loading-error/handle-loading-error';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

function calculateMenopauseStatusDistribution(patients: Patient[]): { label: string; value: number }[] {
  const statusCategories: Record<string, number> = {
    'pre-menopause': 0,
    'peri-menopause': 0,
    'post-menopause': 0,
    'not concerned': 0
  };

  patients.forEach(patient => {
    const status = patient.menopause_status;

    if (status !== null) {
      statusCategories[status]++;
    }
  });

  // Convertir l'objet en tableau pour le PieChart
  return Object.keys(statusCategories).map(category => ({
    label: category,
    value: statusCategories[category],
  }));
}

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement<unknown> } = {
    1: <Pending />,
    2: <VolunteerActivism />,
    3: <Mail />,
    4: <LocalShipping />,
    5: <Drafts />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function calculateYesNoPercentage(patients: Patient[], category: keyof Patient): { yes: number; no: number } {
  let yesCount = 0;
  let noCount = 0;

  patients.forEach(patient => {
    const value = patient[category];
    if (value === 'yes') {
      yesCount++;
    } else if (value === 'no') {
      noCount++;
    }
  });

  const total = yesCount + noCount;

  return {
    yes: (yesCount / total) * 100,
    no: (noCount / total) * 100,
  };
}

type BMICategory =
  | 'Insuffisance pondérale (BMI < 18.5)'
  | 'Poids normal (18.5 <= BMI < 25)'
  | 'Surpoids (25 <= BMI < 30)'
  | 'Obésité classe 1 (30 <= BMI < 35)'
  | 'Obésité classe 2 (35 <= BMI < 40)'
  | 'Obésité classe 3 (BMI >= 40)';

// Fonction pour catégoriser les patients selon leur BMI
function categorizePatientsByBMI(patients: Patient[]): { label: string; value: number }[] {
  const bmiCategories: Record<BMICategory, number> = {
    'Insuffisance pondérale (BMI < 18.5)': 0,
    'Poids normal (18.5 <= BMI < 25)': 0,
    'Surpoids (25 <= BMI < 30)': 0,
    'Obésité classe 1 (30 <= BMI < 35)': 0,
    'Obésité classe 2 (35 <= BMI < 40)': 0,
    'Obésité classe 3 (BMI >= 40)': 0,
  };

  patients.forEach(patient => {
    const bmi = patient.bmi;

    if (bmi < 18.5) {
      bmiCategories['Insuffisance pondérale (BMI < 18.5)']++;
    } else if (bmi >= 18.5 && bmi < 25) {
      bmiCategories['Poids normal (18.5 <= BMI < 25)']++;
    } else if (bmi >= 25 && bmi < 30) {
      bmiCategories['Surpoids (25 <= BMI < 30)']++;
    } else if (bmi >= 30 && bmi < 35) {
      bmiCategories['Obésité classe 1 (30 <= BMI < 35)']++;
    } else if (bmi >= 35 && bmi < 40) {
      bmiCategories['Obésité classe 2 (35 <= BMI < 40)']++;
    } else if (bmi >= 40) {
      bmiCategories['Obésité classe 3 (BMI >= 40)']++;
    }
  });

  // Convertir l'objet en tableau pour le PieChart
  return (Object.keys(bmiCategories) as BMICategory[]).map(category => ({
    label: category,
    value: bmiCategories[category],
  }));
}

function getMonthYearFromTimestamp(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
}
  
function groupPatientsByMonth(patients: Patient[]): { [monthYear: string]: number } {
  const groupedPatients: { [monthYear: string]: number } = {};
  
  patients.forEach(patient => {
    const monthYear = getMonthYearFromTimestamp(patient.timestamp_creation);
  
    if (!groupedPatients[monthYear]) {
      groupedPatients[monthYear] = 0;
    }
  
    groupedPatients[monthYear]++;
  });
  
  return groupedPatients;
}
  
function calculateAge(birthdate: string): number {
  const now = new Date();
      
  let birthDateParts;
  if (birthdate.includes('-')) {
    const isYearFirst = birthdate.indexOf('-') === 4;
        
    if (isYearFirst) {
      birthDateParts = birthdate.split('-');
    } else {
      birthDateParts = birthdate.split('-').reverse();
    }
  } else {
    console.log(birthdate);
    throw new Error('Format de date non pris en charge');
  }
    
  const birthDate = new Date(parseInt(birthDateParts[0]), parseInt(birthDateParts[1]) - 1, parseInt(birthDateParts[2]));
      
  let age = now.getFullYear() - birthDate.getFullYear();
  const monthDiff = now.getMonth() - birthDate.getMonth();
    
  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Fonction pour compter les patients par âge et s'assurer que tous les âges dans la plage sont représentés à partir de 18 ans
function countPatientsByAge(patients: Patient[]): { [age: number]: number } {
  const ageCount: { [age: number]: number } = {};
  const minAge = 18;  // Borne inférieure fixée à 18 ans
  let maxAge = -Infinity;
    
  patients.forEach(patient => {
    try {
      const age = calculateAge(patient.birthdate);
        
      if (age > maxAge) maxAge = age;
        
      if (!ageCount[age]) {
        ageCount[age] = 0;
      }
      ageCount[age]++;
    } catch (error) {
      console.error(`Erreur lors du traitement du patient ${patient.id} : ${error}`);
    }
  });
    
  // Remplir les âges manquants avec 0, en commençant à 18 ans
  for (let age = minAge; age <= maxAge; age++) {
    if (!ageCount[age]) {
      ageCount[age] = 0; // Remplir avec 0 si aucun patient n'a cet âge
    }
  }
    
  return ageCount;
}
  
function countPatientsBySex(patients: Patient[]): { male: number; female: number } {
  const sexCount = { male: 0, female: 0 };
    
  patients.forEach(patient => {
    if (patient.sex === 'man') {
      sexCount.male++;
    } else if (patient.sex === 'women') {
      sexCount.female++;
    }
  });
    
  return sexCount;
}

export default function DataPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useSelector(adminSelector);
  const kitData = useSelector(dataSelector).kitData;
  const sites = useSelector(siteSelector).sites.filter( site => user.admin_role === AdminRole.Admin ? true : user.site.includes(site.id));
  const [selectedSite, setSelectedSite] = useState<string[]>(sites.map(site => site.id));
  const [selectedStatus, setSelectedStatus] = useState<string[]>(Object.values(PatientStatus));
  const [selectedPatientGroup, setSelectedPatientGroup] = useState<string[]>(Object.values(PatientGroup));
  const patients = useSelector(patientSelector).patients;
  const [filteredPatient, setFilteredPatient] = useState<Patient[]>([]);
  const [ageLabels, setAgeLabels] = useState<string[]>([]);
  const [ageData, setAgeData] = useState<number[]>([]);
  const [creationLabels, setCreationLabels] = useState<string[]>([]);
  const [creationData, setCreationData] = useState<number[]>([]);
  const [sexData, setSexData] = useState<{ male: number; female: number }>({ male: 0, female: 0 });
  const [bmiData, setBmiData] = useState<{ label: string; value: number }[]>([]);
  const [antiData, setAntiData] = useState<{ category: string; yes: number; no: number }[]>([]);
  const [menopauseData, setMenopauseData] = useState<{ label: string; value: number }[]>([]);
  const { setIsError } = useAppContext();
  const [kitDataFiltered, setKitDataFiltered] = useState<ResponseKitData | null>(null);

  const [getPatients] = useGetPatientsMutation();
  const [getDataKit] = useGetAnalyseKitDataMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [kitDataResponse, dataPatients] = await Promise.all([
          getDataKit().unwrap(),
          patients.length === 0 ? getPatients().unwrap() : Promise.resolve(null),
        ]);
        dispatch(setKitData(kitDataResponse));
        if (dataPatients) {
          dispatch(setPatients(dataPatients));
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    (patients.length === 0 || kitData === undefined) && fetchData();
  }, [dispatch, getDataKit, getPatients, kitData, patients.length, setIsError])
  
  useEffect(() => {
    const ageCount = countPatientsByAge(filteredPatient);

    setAgeLabels(Object.keys(ageCount).map(age => age));
    setAgeData(Object.values(ageCount).map(count => count));

    const countedSexData = countPatientsBySex(filteredPatient);
    setSexData(countedSexData);

    const groupedByMonth = groupPatientsByMonth(filteredPatient);

    const sortedLabels = Object.keys(groupedByMonth).sort((a, b) => {
      const dateA = new Date(`${a}-01`);
      const dateB = new Date(`${b}-01`);

      if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
        return dateA.getTime() - dateB.getTime();
      } else {
        throw new Error("Invalid date format for sorting");
      }
    });
    
    const sortedData = sortedLabels.map(label => groupedByMonth[label]);

    setCreationLabels(sortedLabels);
    setCreationData(sortedData);

    const bmiDistribution = categorizePatientsByBMI(filteredPatient);

    setBmiData(bmiDistribution);

    const categories: (keyof Patient)[] = ['antihypertensives', 'anti_lipid', 'anti_obesogens', 'nursing'];

    const antiData = categories.map(category => {
      const percentages = calculateYesNoPercentage(filteredPatient, category);
      return {
        category,  // Nom de la catégorie
        yes: percentages.yes,  // Pourcentage de "yes"
        no: percentages.no,  // Pourcentage de "no"
      };
    });

    setAntiData(antiData);

    const menopauseDistribution = calculateMenopauseStatusDistribution(filteredPatient);

    // Mettre à jour les données du PieChart
    setMenopauseData(menopauseDistribution);
    
  }, [filteredPatient]);

  useEffect(() => {
    if(kitData !== undefined) {
      const patientList = filteredPatient.map(patient => patient.id)
      setKitDataFiltered({
        "attributed_patients": kitData.attributed_patients.filter(item => patientList.includes(item)),
        "sent_patients": kitData.sent_patients.filter(item => patientList.includes(item)),
        "shipping_patients": kitData.shipping_patients.filter(item => patientList.includes(item)),
        "received_patients": kitData.received_patients.filter(item => patientList.includes(item))
      });
    }
  
  }, [filteredPatient, kitData]); 

  const handleSiteChange = (event: React.SyntheticEvent, newValue: string) => {
    if (selectedSite.includes(newValue)) {
      setSelectedSite(prevSelected => prevSelected.filter(value => value !== newValue));
    } else {
      setSelectedSite(prevSelected => [...prevSelected, newValue]);
    }
  };

  const selectAllSite = () => {
    setSelectedSite(sites.map(site => site.id));
  };

  const deselectAllSites = () => {
    setSelectedSite([]);
  };

  const handleStatusChange = (event: React.SyntheticEvent, newValue: string) => {
    if (selectedStatus.includes(newValue)) {
      setSelectedStatus(prevSelected => prevSelected.filter(value => value !== newValue));
    } else {
      setSelectedStatus(prevSelected => [...prevSelected, newValue]);
    }
  };

  const selectAllStatus = () => {
    setSelectedStatus(Object.values(PatientStatus));
  };

  const deselectAllStatus = () => {
    setSelectedStatus([]);
  };

  const handlePatientGroupChange = (event: React.SyntheticEvent, newValue: string) => {
    if (selectedPatientGroup.includes(newValue)) {
      setSelectedPatientGroup(prevSelected => prevSelected.filter(value => value !== newValue));
    } else {
      setSelectedPatientGroup(prevSelected => [...prevSelected, newValue]);
    }
  };

  const selectAllGroups = () => {
    setSelectedPatientGroup(Object.values(PatientGroup));
  };

  const deselectAllGroups = () => {
    setSelectedPatientGroup([]);
  };

  useEffect(() => {
    setFilteredPatient(patients.filter(patient => [PatientStatus.Included, PatientStatus.Finished].includes(patient.patient_status)).filter(patient => selectedSite.includes(patient.site) ).filter(patient => selectedStatus.includes(patient.patient_status)).filter(patient => selectedPatientGroup.includes(patient.patient_group)))
  }, [patients, selectedPatientGroup, selectedSite, selectedStatus])

  const width = window.innerWidth;

  const pieSexData = [
    { label: 'Hommes', value: sexData.male },
    { label: 'Femmes', value: sexData.female }
  ];

  const labels = antiData.map(data => data.category);
  const yesData = antiData.map(data => data.yes);
  const noData = antiData.map(data => data.no);

  interface Steps {
    "Attribué": string,
    "Envoyé": string,
    "En cours de transport": string, 
    "Reçu":string
    }

  const steps = ['Attribué', 'Envoyé', 'En cours de transport', 'Reçu'];

  const style = {
    chartTitle: {
      fontWeight: 'bold',
      fontSize: 20,
      textAlign: 'center'
    },
    flexRow: {display: 'flex', flexDirection: 'row', marginBottom: 5}
  }

  const joinKitData : Steps = {
    "Attribué": "attributed_patients",
    "Envoyé": "sent_patients",
    "En cours de transport": "shipping_patients",
    "Reçu": "received_patients",
  }

  return (
    <HandleLoadingError isError={false} isLoading={isLoading}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
          Data page
        </Typography>
        <Box sx={{ display: 'flex', width: width * 0.90, boxShadow: 1, borderRadius: 3, backgroundColor: 'white', paddingX: 2, marginBottom: 3}}>
          <Tabs
            value={false} // Désactive la sélection unique
            onChange={handleSiteChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {
              sites.map((site) => (
                <Tab
                  value={site.id}
                  key={site.id}
                  label={site.city}
                  style={{
                    backgroundColor: selectedSite.includes(site.id) ? '#d1e0ff' : 'transparent',
                    fontWeight: selectedSite.includes(site.id) ? 'bold' : 'normal',
                  }}
                />
              ))
            }
          </Tabs>
          <Button onClick={selectAllSite}>Sélectionner tous</Button>
          <Button onClick={deselectAllSites}>Désélectionner tous</Button>
        </Box>
        <Box sx={{ display: 'flex', width: width * 0.90, boxShadow: 1, borderRadius: 3, backgroundColor: 'white', paddingX: 2, marginBottom: 3}}>
          <Tabs
            value={false} // Désactive la sélection unique
            onChange={handleStatusChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {
              Object.entries(PatientStatus).map(([key, value]) => (
                <Tab
                  value={value}
                  key={key}
                  label={value}
                  style={{
                    backgroundColor: selectedStatus.includes(value) ? '#d1e0ff' : 'transparent',
                    fontWeight: selectedStatus.includes(value) ? 'bold' : 'normal',
                  }}
                />
              ))
            }
          </Tabs>
          <Button onClick={selectAllStatus}>Sélectionner tous</Button>
          <Button onClick={deselectAllStatus}>Désélectionner tous</Button>
        </Box>
        <Box sx={{ display: 'flex', width: width * 0.90, boxShadow: 1, borderRadius: 3, backgroundColor: 'white', paddingX: 2}}>
          <Tabs
            value={false} // Désactive la sélection unique
            onChange={handlePatientGroupChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {
              Object.entries(PatientGroup).map(([key, value]) => (
                <Tab
                  value={value}
                  key={key}
                  label={value}
                  style={{
                    backgroundColor: selectedPatientGroup.includes(value) ? '#d1e0ff' : 'transparent',
                    fontWeight: selectedPatientGroup.includes(value) ? 'bold' : 'normal',
                  }}
                />
              ))
            }
          </Tabs>
          <Button onClick={selectAllGroups}>Sélectionner tous</Button>
          <Button onClick={deselectAllGroups}>Désélectionner tous</Button>
        </Box>
        <Box sx={{ width: '100%'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 2,
              backgroundColor: 'white',
              boxShadow: 1,
              borderRadius: 5,
              marginY: 3,
              padding: 5
            }}
          >
            <Typography textAlign={'center'} variant='h5' sx={{marginBottom: 3}}>Suivie des kits</Typography>
            <Stepper alternativeLabel activeStep={4} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography>{label}</Typography>
                    <Typography>{kitDataFiltered?.[joinKitData[label as keyof Steps] as keyof ResponseKitData]?.length}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
            backgroundColor: 'white',
            boxShadow: 1,
            borderRadius: 5,
            marginY: 3,
            padding: 5
          }}
        >
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={style.flexRow}>
              <Box sx={{flex: 1}}>
                <Typography sx={style.chartTitle}>Patients par âge</Typography>
                <BarChart
                  xAxis={[{ label: 'Âge', data: ageLabels, scaleType: 'band' }]}
                  series={[{ label: 'Nombre de patients', data: ageData }]}
                  height={400}
                  width={0.45 * width}
                  sx={{flex: 1, width: 1000}}
                />
              </Box>
              <Box sx={{flex: 1}}>
                <Typography sx={style.chartTitle}>Patients par date d&apos;inscription</Typography>
                <BarChart
                  xAxis={[{ label: 'Inscription', data: creationLabels, scaleType: 'band' }]}
                  series={[{ label: 'Nombre de patients', data: creationData }]}
                  height={400}
                  width={0.45 * width}
                />
              </Box>
            </Box>
            <Box sx={style.flexRow}>
              <Box sx={{flex: 1}}>
                <Typography sx={style.chartTitle}>Patients par sexe</Typography>
                <PieChart
                  series={[
                    {
                      data: pieSexData,
                    }
                  ]}
                  height={400}
                  sx={{flex: 1}}
                />
              </Box>
              <Box sx={{flex: 2}}>
                <Typography sx={style.chartTitle}>Patients par imc</Typography>
                <PieChart
                  series={[
                    {
                      data: bmiData,
                    }
                  ]}
                  height={400}
                  sx={{flex: 2}}
                />
              </Box>
            </Box>
            <Box sx={style.flexRow}>
              <Box sx={{flex: 1}}>
                <Typography sx={style.chartTitle}>Patients par type de menopause</Typography>
                <PieChart
                  series={[
                    {
                      data: menopauseData,
                    }
                  ]}
                  height={400}
                  sx={{flex: 1}}
                />
              </Box>
            </Box>
            <Box sx={style.flexRow}>
              <Box sx={{flex: 1}}>
                <Typography sx={style.chartTitle}>Patients par sexe</Typography>
                <BarChart
                  xAxis={[{ label: 'Catégories', data: labels, scaleType: 'band' }]}  // Axe des X avec les catégories
                  series={[
                    { label: '% Oui', data: yesData },  // Séries des réponses "yes"
                    { label: '% Non', data: noData },    // Séries des réponses "no"
                  ]}
                  width={0.9 * width}
                  height={400}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </HandleLoadingError>
  );
}
