/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, FormControl, Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useGetAnalyseDataByKitIdMutation, useGetAnalyseDataByPatientIdMutation, useGetPatientMutation, usePostAnalyseDataMutation } from '../store/api';
import { Patient, PatientStatus } from '../types/types';

type FormData = {
  '2aef3a25-8029-4710-b592-79b6aa9fe2bb': string;
  'bab3c091-7061-4bf2-b791-84e117c5060d': string;
  'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': string;
  '682becdc-9bfc-491b-aedf-311113870245': string;
  'f8216a50-1130-4bf8-8f05-96a0529fb445': string;
  '0aaf5db3-1494-487b-9d21-3c5953421f33': string;
  '688219b9-8935-4fdf-bcaf-d4e8a28b2064': string;
  '746b22d3-76c2-4d5d-9ea1-455bd30a1965': string;
  '3ef125b9-c460-4b54-95c2-bae1062adb59': string;
  'b7f7909e-9374-4270-909d-7963765726b0': string;
  '078f12e3-a41e-481f-ac21-6f9cc71a702b': string;
};

export default function AnalyseKitPage() {
  const [kitId, setKitId] = useState<string>('');
  const [patientId, setPatientId] = useState<string>('');
  const [patient, setPatient] = useState<Patient | null>(null);
  const [searchByKitError, setSearchByKitError] = useState<string>('');
  const [searchByPatientError, setSearchByPatientError] = useState<string>('');
  const [isPatient, setIsPatient] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    '2aef3a25-8029-4710-b592-79b6aa9fe2bb': '',
    'bab3c091-7061-4bf2-b791-84e117c5060d': '',
    'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': '',
    '682becdc-9bfc-491b-aedf-311113870245': '',
    'f8216a50-1130-4bf8-8f05-96a0529fb445': '',
    '0aaf5db3-1494-487b-9d21-3c5953421f33': '',
    '688219b9-8935-4fdf-bcaf-d4e8a28b2064': '',
    '746b22d3-76c2-4d5d-9ea1-455bd30a1965': '',
    '3ef125b9-c460-4b54-95c2-bae1062adb59': '',
    'b7f7909e-9374-4270-909d-7963765726b0': '',
    '078f12e3-a41e-481f-ac21-6f9cc71a702b': ''
  });
  const [postAnalyseData] = usePostAnalyseDataMutation();
  const [getPatient] = useGetPatientMutation();
  const [getAnalyseDataByKit] = useGetAnalyseDataByKitIdMutation();
  const [getAnalyseDataByPatient] = useGetAnalyseDataByPatientIdMutation();

  const handleChange = async (event: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value,
    }));
    patient && await postAnalyseData({'id': patient?.id, data: {answer: value, question_id: name}}).unwrap();
  };

  const handleChangeKitId = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setKitId(value);
  }

  const handleChangePatientId = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setPatientId(value);
  }

  const searchKitByKit = async () => {
    try {
      setIsLoading(true);
      setSearchByKitError('');
      setFormData({
        '2aef3a25-8029-4710-b592-79b6aa9fe2bb': '',
        'bab3c091-7061-4bf2-b791-84e117c5060d': '',
        'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': '',
        '682becdc-9bfc-491b-aedf-311113870245': '',
        'f8216a50-1130-4bf8-8f05-96a0529fb445': '',
        '0aaf5db3-1494-487b-9d21-3c5953421f33': '',
        '688219b9-8935-4fdf-bcaf-d4e8a28b2064': '',
        '746b22d3-76c2-4d5d-9ea1-455bd30a1965': '',
        '3ef125b9-c460-4b54-95c2-bae1062adb59': '',
        'b7f7909e-9374-4270-909d-7963765726b0': '',
        '078f12e3-a41e-481f-ac21-6f9cc71a702b': ''
      });
      const response = await getAnalyseDataByKit(kitId).unwrap();
      console.log(response)
      if ('message' in response) {
        setIsPatient(false);
        setSearchByKitError('Kit pas trouvé');
        return
      }

      if ('patient_id' in response && response['patient_id'] === 'none') {
        setIsPatient(false);
        setSearchByKitError('Kit non attribué');
        return
      }

      if(response) {
        setIsPatient(true);
        const patientResponse = await getPatient(response.patient_id).unwrap();
        setPatient(patientResponse)
        setIsDisable(patientResponse.patient_status !== PatientStatus.Included && patientResponse.patient_status !== PatientStatus.Finished)
      }

      const answer = response.data.reduce((acc, x) => {
        acc[x.question_id as keyof FormData] = x.answer;
        return acc;
      }, {} as FormData);

      setFormData({
        ...answer
      })
    } catch (err : Error | any) {
      setSearchByKitError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  const searchKitByPatient = async () => {
    try {
      setIsLoading(true);
      setSearchByPatientError('');
      setFormData({
        '2aef3a25-8029-4710-b592-79b6aa9fe2bb': '',
        'bab3c091-7061-4bf2-b791-84e117c5060d': '',
        'e2cfcc73-9400-4cbc-a8ad-a8e154167f40': '',
        '682becdc-9bfc-491b-aedf-311113870245': '',
        'f8216a50-1130-4bf8-8f05-96a0529fb445': '',
        '0aaf5db3-1494-487b-9d21-3c5953421f33': '',
        '688219b9-8935-4fdf-bcaf-d4e8a28b2064': '',
        '746b22d3-76c2-4d5d-9ea1-455bd30a1965': '',
        '3ef125b9-c460-4b54-95c2-bae1062adb59': '',
        'b7f7909e-9374-4270-909d-7963765726b0': '',
        '078f12e3-a41e-481f-ac21-6f9cc71a702b': ''
      });
      const response = await getAnalyseDataByPatient(patientId).unwrap();
      if ('message' in response) {
        setIsPatient(false);
        setSearchByPatientError('Kit pas trouvé');
        return
      }

      if ('patient_id' in response && response['patient_id'] === 'none') {
        setIsPatient(false);
        setSearchByPatientError('Kit non attribué');
        return
      }

      if(response) {
        setIsPatient(true);
        const patientResponse = await getPatient(patientId).unwrap();
        setPatient(patientResponse)
        setIsDisable(patientResponse.patient_status !== PatientStatus.Included && patientResponse.patient_status !== PatientStatus.Finished)
      }

      const answer = response.data.reduce((acc, x) => {
        acc[x.question_id as keyof FormData] = x.answer;
        return acc;
      }, {} as FormData);

      setFormData({
        ...answer
      })
    } catch (err : Error | any) {
      setSearchByPatientError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
      }}>
      <h1>Admins</h1>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          padding: 3,
          flexDirection: 'column'
        }}
      >
        <Box sx={{
          display: 'flex',
          backgroundColor: 'white'}}>
          <Box>
            <TextField
              label={"Numéro de kit"}
              value={kitId}
              onChange={handleChangeKitId}
            />
            <Typography sx={{color: 'red', marginY: 1}} variant='subtitle2'>{searchByKitError}</Typography>
          </Box>
            
          <Box>
            <Button sx={{marginX: 3}} onClick={searchKitByKit} variant="contained">Rechercher</Button>
          </Box>
          <Box>
            <TextField
              label={"Numéro de patient"}
              value={patientId}
              onChange={handleChangePatientId}
            />
            <Typography sx={{color: 'red', marginY: 1}} variant='subtitle2'>{searchByPatientError}</Typography>
          </Box>
            
          <Box>
            <Button sx={{marginX: 3}} onClick={searchKitByPatient} variant="contained">Rechercher</Button>
          </Box>
        </Box>

        {
          isLoading ? (
            <Box sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><CircularProgress /></Box>
          ) : isPatient && (
            <Box>
              <Typography sx={{marginY: 2}} variant='h5'>Données entrées par le patient</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="patientId"
                      name="patientId"
                      label="Id patient"
                      value={patient?.id}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="type"
                      name="682becdc-9bfc-491b-aedf-311113870245"
                      label="Type bristol"
                      disabled={isDisable}
                      onChange={handleChange}
                      value={formData['682becdc-9bfc-491b-aedf-311113870245']}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date1"
                      name="2aef3a25-8029-4710-b592-79b6aa9fe2bb"
                      label="Date prélèvement"
                      disabled={isDisable}
                      value={formData['2aef3a25-8029-4710-b592-79b6aa9fe2bb']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date2"
                      name="bab3c091-7061-4bf2-b791-84e117c5060d"
                      label="Date kit envoyé"
                      disabled={isDisable}
                      value={formData['bab3c091-7061-4bf2-b791-84e117c5060d']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Typography sx={{marginY: 2}} variant='h5'>Données après réception</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text1"
                      name="f8216a50-1130-4bf8-8f05-96a0529fb445"
                      label="Id kit reçu"
                      disabled={isDisable}
                      value={formData['f8216a50-1130-4bf8-8f05-96a0529fb445']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="date"
                      id="date3"
                      name="e2cfcc73-9400-4cbc-a8ad-a8e154167f40"
                      label="Date de reception"
                      disabled={isDisable}
                      value={formData['e2cfcc73-9400-4cbc-a8ad-a8e154167f40']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text2"
                      name="688219b9-8935-4fdf-bcaf-d4e8a28b2064"
                      label="Code kit reçu"
                      disabled={isDisable}
                      value={formData['688219b9-8935-4fdf-bcaf-d4e8a28b2064']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text3"
                      name="746b22d3-76c2-4d5d-9ea1-455bd30a1965"
                      label="Box"
                      disabled={isDisable}
                      value={formData['746b22d3-76c2-4d5d-9ea1-455bd30a1965']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text4"
                      name="3ef125b9-c460-4b54-95c2-bae1062adb59"
                      label="Position"
                      disabled={isDisable}
                      value={formData['3ef125b9-c460-4b54-95c2-bae1062adb59']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text5"
                      name="b7f7909e-9374-4270-909d-7963765726b0"
                      label="Id patient du kit reçu"
                      disabled={isDisable}
                      value={formData['b7f7909e-9374-4270-909d-7963765726b0']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControl>
                    <TextField
                      type="text"
                      id="text6"
                      name="078f12e3-a41e-481f-ac21-6f9cc71a702b"
                      label="Type bristol reçu"
                      disabled={isDisable}
                      value={formData['078f12e3-a41e-481f-ac21-6f9cc71a702b']}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}
