import { combineReducers } from '@reduxjs/toolkit';
import {
  adminReducer,
  patientReducer,
  dataReducer,
  questionReducer,
  surveyReducer,
  answerReducer,
  siteReducer,
  errorReducer,
} from './slices';
import { adminApi, dataApi, patientApi, questionApi, answerApi, surveyApi, siteApi, adverseEventApi } from './api';
import { adverseEventReducer } from './slices/adverseEventSlice';
import { globalLogout } from './actions';

const appReducer = combineReducers({
  admin: adminReducer,
  data: dataReducer,
  patient: patientReducer,
  question: questionReducer,
  survey: surveyReducer,
  answer: answerReducer,
  site: siteReducer,
  adverseEvent: adverseEventReducer,
  error: errorReducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [dataApi.reducerPath]: dataApi.reducer,
  [patientApi.reducerPath]: patientApi.reducer,
  [questionApi.reducerPath]: questionApi.reducer,
  [answerApi.reducerPath]: answerApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [adverseEventApi.reducerPath]: adverseEventApi.reducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
  if (action.type === globalLogout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
