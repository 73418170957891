import { logoutAdmin } from './slices/adminSlice';
import { logoutAdverseEvent} from './slices/adverseEventSlice';
import { logoutAnswer } from './slices/answerSlice';
import { logoutData } from './slices/dataSlice';
import { logoutPatient } from './slices/patientSlice';
import { logoutQuestion } from './slices/questionSlice';
import { logoutSites } from './slices/siteSlice';
import { logoutSurvey } from './slices/surveySlice';
import { logoutError } from './slices/errorSlice';
import { AppDispatch } from './store';
import { createAction } from '@reduxjs/toolkit';

export const logoutAll = () => (dispatch: AppDispatch) => {
  console.log('test')
  dispatch(logoutAdmin());
  dispatch(logoutAdverseEvent());
  dispatch(logoutAnswer());
  dispatch(logoutData());
  dispatch(logoutError());
  dispatch(logoutPatient());
  dispatch(logoutQuestion());
  dispatch(logoutSites());
  dispatch(logoutSurvey());

  dispatch(globalLogout());
};

export const globalLogout = createAction('auth/globalLogout');
