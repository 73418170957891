import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './rootReducer';
import { adminApi, dataApi, patientApi, questionApi, answerApi, surveyApi, siteApi, adverseEventApi } from './api';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(adminApi.middleware, dataApi.middleware, patientApi.middleware, questionApi.middleware, answerApi.middleware, surveyApi.middleware, siteApi.middleware, adverseEventApi.middleware),
});

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
export { store };

export * from './selectors';
