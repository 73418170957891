import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminState } from '../type';
import { Admin, AdminRole, Query } from '../../types/types';

const adminSlice = createSlice({
  name: 'user',
  initialState: {
    adminId: '',
    first_name: '',
    last_name: '',
    site: [],
    email: '',
    admin_role: AdminRole.Investigator,
    admins: [],
    adminRules: [],
    query: []
  } as AdminState,
  reducers: {
    setAdmin(state, action: PayloadAction<AdminState>) {
      state.adminId = action.payload.adminId;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.site = action.payload.site;
      state.email = action.payload.email;
      state.admin_role = action.payload.admin_role;
      state.adminRules = action.payload.adminRules;
      state.query = action.payload.query;
    },
    setAdmins(state, action: PayloadAction<Admin[]>) {
      state.admins = action.payload;
    },
    setAdminQuery(state, action: PayloadAction<Query[]>) {
      state.query = action.payload;
    },
    logoutAdmin(state) {
      state.adminId = '';
      state.first_name = '';
      state.last_name = '';
      state.site = [];
      state.email = '';
      state.admin_role = AdminRole.Investigator;
      state.admins = [];
      state.adminRules = [];
      state.query = [];
    },
  },
});

export const { setAdmin, logoutAdmin, setAdminQuery, setAdmins } = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
