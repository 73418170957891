import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { usePostTransferToTouvaMutation } from "../../store/api";
import { useDispatch, useSelector } from "react-redux";
import { isPatientSelector } from "../../store/selectors";
import { setPatient } from "../../store/slices";

interface TransferModalProps {
  open: boolean;
  setClose: () => void;
  onTransfer: (transfer: boolean) => void;
}

const TransferModal: React.FC<TransferModalProps> = ({ open, setClose, onTransfer }) => {
  const dispatch = useDispatch();
  const patient = useSelector(isPatientSelector).patient;

  const [uuid, setUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");

  const [transferToTouva] = usePostTransferToTouvaMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setApiError("");
    setError("");

    if (!uuid.trim()) {
      setError("Le champ UUID est requis.");
      return;
    }

    if (!/^[a-zA-Z0-9-]+$/.test(uuid)) {
      setError("Veuillez entrer un UUID valide.");
      return;
    }

    try {
      setIsLoading(true);
      if (patient.transferred === false) {
        await transferToTouva({ id: patient.id, doctor_id: uuid }).unwrap();
        dispatch(setPatient({ ...patient, transferred: true }));
        onTransfer(true);
        setClose();
      }
    } catch {
      setApiError("Une erreur est survenue lors du transfert.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={setClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" mb={2}>
          Affiliation au Docteur
        </Typography>
        <Typography variant="body1" mb={3}>
          Veuillez entrer le code UUID du docteur pour affilier ce patient à un futur docteur.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="UUID du docteur"
            variant="outlined"
            value={uuid}
            onChange={(e) => setUuid(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{ mb: 3 }}
          />
          {apiError && (
            <Typography variant="body2" color="error" mb={2}>
              {apiError}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={20} />}
            >
              Transférer
            </Button>
            <Button variant="outlined" color="secondary" onClick={setClose}>
              Fermer
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default TransferModal;
