import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, CircularProgress, IconButton, Menu, MenuItem, Modal, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { adminSelector, patientSelector } from '../store/selectors';
import { useDeletePatientMutation, useGetAnalyseKitCsvMutation, useGetPatientsMutation, useGetPatientToPayedMutation, usePutPatientMutation, useResetPasswordMutation } from '../store/api';
import { setPatients } from '../store/slices';
import { CheckCircle, Edit, MoreHoriz, PersonAddAlt, Refresh } from '@mui/icons-material';
import { AdminRole, PatientGroup, PatientStatus, ResponseKitCsv, ResponsePayement } from '../types/types';
import CopyButton from '../components/button/copy-button';
import { useAppContext } from '../components/authentication/account';
import DownloadIcon from '@mui/icons-material/Download';
import WarningIcon from '@mui/icons-material/Warning';
import EuroIcon from '@mui/icons-material/Euro';
import PatientsExpiredDashboard from '../components/information/patients-expired-dashboard';
import * as XLSX from 'xlsx';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'column'
};

const isResponseKitCsv = (item: ResponseKitCsv | ResponsePayement): item is ResponseKitCsv => {
  return 'specificPropertyForKitCsv' in item; // Remplacez par une propriété unique à ResponseKitCsv
};

const generateXLSX = (data: (ResponseKitCsv | ResponsePayement)[], fileName: string) => {
  if (!data.length) return;

  // Dynamically get headers based on the type of the first item
  const headers = Object.keys(data[0]);

  // Generate rows with headers and data
  const rows = data.map(row =>
    headers.reduce((acc, header) => {
      if (isResponseKitCsv(row)) {
        // Access properties for ResponseKitCsv
        acc[header] = row[header as keyof ResponseKitCsv] || '';
      } else {
        // Access properties for ResponsePayement
        acc[header] = row[header as keyof ResponsePayement] || '';
      }
      return acc;
    }, {} as Record<string, any>)
  );

  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(rows);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Données');

  // Write the workbook to a Blob and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName + '.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function PatientsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminId = useSelector(adminSelector).adminId;
  const adminRole = useSelector(adminSelector).admin_role;
  const globalPatients = useSelector(patientSelector).patients;
  const sortedPatients = [...globalPatients].sort((a, b) => {
    const timeA = Number(a.timestamp_creation) || 0;
    const timeB = Number(b.timestamp_creation) || 0;
    return timeB - timeA;
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);
  const [isLoadingPayement, setIsLoadingPayement] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { setIsError } = useAppContext();
  const [getPatients] = useGetPatientsMutation();
  const [
    deletePatient,
  ] = useDeletePatientMutation();
  const [
    resetPassword,
  ] = useResetPasswordMutation();
  const [
    putPatient,
  ] = usePutPatientMutation();
  const [getAnalyseKitCsv] = useGetAnalyseKitCsvMutation();
  const [getPatientToPayed] = useGetPatientToPayedMutation();
  const width = window.innerWidth;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<string>('');
  const [report, setReport] = React.useState<string>('');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen('');
  };
  const [optionModalFunction, setOptionModalFunction] = useState<() => Promise<void>>();

  const [selectedPatient, setSelectedPatient] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [openOptionModal, setOpenOptionModal] = React.useState(false);
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenOptionModal = () => setOpenOptionModal(true);
  const handleCloseOptionModal = () => setOpenOptionModal(false);
  const handleOpenReportModal = () => setOpenReportModal(true);
  const handleCloseReportModal = () => setOpenReportModal(false);

  const [filter, setFilter] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(event.target.value);
  };

  const handleDropOutPatient = async () => {
    try {
      setIsLoading(true);
      let patientToChange = globalPatients.find(patient => patient.id === selectedPatient)
      if (patientToChange !== undefined && patientToChange.patient_status !== PatientStatus.DropOut){
        patientToChange = Object.assign({}, patientToChange, {'patient_status': PatientStatus.DropOut});
        await putPatient(patientToChange).unwrap()
        if (adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePatient = async () => {
    try {
      setIsLoading(true);
      let patientToChange = globalPatients.find(patient => patient.id === selectedPatient)
      if (patientToChange !== undefined && patientToChange.patient_status !== PatientStatus.Deleted){
        patientToChange = Object.assign({}, patientToChange, {'patient_status': PatientStatus.Deleted});
      
        await putPatient(patientToChange).unwrap()
        if (adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDefinitivelyPatient = async () => {
    try {
      setIsLoading(true);
      await deletePatient(({id: selectedPatient})).unwrap()
      if (adminId) {
        const patients = await getPatients().unwrap();
        dispatch(
          setPatients(patients)
        );
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPatientPassword = async () => {
    try {
      setIsLoading(true);
      const response = await resetPassword({id: selectedPatient}).unwrap();
      setId(selectedPatient);
      setPassword(response.password)
      handleOpenModal();
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportPatient = async () => {
    try {
      setIsLoading(true);
      let patientToChange = globalPatients.find(patient => patient.id === selectedPatient)
      if (patientToChange !== undefined && patientToChange.patient_status !== PatientStatus.DropOut){
        patientToChange = Object.assign({}, patientToChange, {'report': report});
        patientToChange && await putPatient(patientToChange).unwrap();
        if (adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      handleCloseReportModal();
    }
  };

  const handleChangeReport = (event: ChangeEvent<HTMLInputElement>) => {
    setReport(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (adminId) {
          const patients = await getPatients().unwrap();
          dispatch(
            setPatients(patients)
          );
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    globalPatients.length === 0 && fetchData();
  }, [adminId, dispatch, getPatients, globalPatients.length, setIsError])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: width * 0.1 },
    { field: 'patient_group', headerName: 'Groupe', width: width * 0.12, 
      renderCell: (params) => {
        switch (params.value) {
        case PatientGroup.ClinicallyAtRisk:
          return 'Cliniquement à risque';
        case PatientGroup.NoClincallyAtRisk:
          return'Pas cliniquement a risque';
        default:
          return 'inconnu'
        }  }},
    { field: 'site', headerName: 'Centre', width: width * 0.06  },
    {
      field: 'timestamp_creation',
      headerName: 'Date de création',
      width: width * 0.09 ,
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    { field: 'step', headerName: 'Progression', width: width * 0.05,
      renderCell: (params) => {
        // Composant personnalisé à afficher dans la cellule
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <h6 style={{ margin: '0' }}>{globalPatients.find(value => value.id === params.id)?.patient_status === PatientStatus.Finished ? 100 : (Number(params.value) / 7 * 100).toFixed(0)}%</h6>
          </Box>
        );
      }
    },
    { field: 'patient_status', headerName: 'Statut', width: width * 0.09,
      renderCell: (params) => {
        let color;
        switch (params.value) {
        case PatientStatus.Selected:
          color= 'yellow';
          break;
        case PatientStatus.Included:
          color= 'green';
          break;
        case PatientStatus.Finished:
          color= 'grey';
          break;
        case PatientStatus.Deleted:
          color = 'red';
          break;
        case PatientStatus.DropOut:
          color= 'orange';
          break;
        default:
          color= 'purple'
        }
        // Composant personnalisé à afficher dans la cellule
        return (
          <Box sx={{width: '100%',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Box sx={{
              width: '10px',
              height: '10px',
              backgroundColor: color,
              borderRadius: '50%'
            }} />
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}>
              <h6 style={{ margin: '0' }}>{params.value}</h6>
            </Box>
          </Box>
        );
      } 
    },
    {
      field: 'last_modification_timestamp',
      headerName: 'Date de modification',
      width: width * 0.1 ,
      renderCell: (params) => {
        const date = new Date(params.value * 1000);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC'
        };
        const formattedDate = date.toLocaleString('fr-FR', options);
        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    {
      field: 'sample_validate',
      headerName: "Selles",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === 'true' ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'r24_validate',
      headerName: "Validation R24",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === 'true' ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'report',
      headerName: "Rapport",
      width: width * 0.07 ,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.value === true ? 'oui' : 'non'}</span>
          </div>
        );
      }
    },
    {
      field: 'modify',
      headerName: 'Modifier',
      width: width * 0.05,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => navigate('/root/patient/' + params.id)}><Edit/></IconButton>
        );
      }
    },
    {
      field: 'option',
      headerName: 'Option',
      width: width * 0.05,
      renderCell: (params) => {
        return (
          <><IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => {
              setSelectedPatient(params.id as string);
              handleClick(event);
              setOpen(params.id as string);
            }}
          >
            <MoreHoriz />
          </IconButton><Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open === params.id}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => { 
              setOptionModalFunction(() => () => handleResetPatientPassword());
              handleClose();
              handleOpenOptionModal();
            }}>Nouveau mdp</MenuItem>
            <MenuItem onClick={() => { 
              setOptionModalFunction(() => () => handleDropOutPatient());
              handleClose();
              handleOpenOptionModal();
            }}>Perdu de vue</MenuItem>
            <MenuItem onClick={() => { 
              setOptionModalFunction(() => () => handleDeletePatient());
              handleClose();
              handleOpenOptionModal();
            }}>Exclure</MenuItem>
            { adminRole === AdminRole.Admin && <MenuItem onClick={() => { 
              setOptionModalFunction(() => () => handleDeleteDefinitivelyPatient());
              handleClose();
              handleOpenOptionModal();
            }}>Supprimer</MenuItem>}
            <MenuItem onClick={() => { 
              setReport( globalPatients.find(patient => patient.id === selectedPatient)?.report_problem ?? '');
              handleClose();
              handleOpenReportModal();
            }}>Signaler</MenuItem>
          </Menu></>
        );
      }
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 3,
      }}>
      <h1>Patients</h1>
      <Box sx={{marginY: 2, display: 'flex', alignItems: 'center'}}>
        <Button
          sx={{marginRight: 2}}
          color="primary"
          onClick={() => navigate('/root/create-patient')}
          size="large"
          variant="contained"
          startIcon={<PersonAddAlt />}
        >Créer un patient</Button>
        <IconButton onClick={async () => {
          try {
            setIsLoading(true);
            if (adminId) {
              const patients = await getPatients().unwrap();
              dispatch(
                setPatients(patients)
              );
            }
          } catch (error) {
            setIsError(true);
          } finally {
            setIsLoading(false);
          }
        }} size='small' sx={{background: 'white', borderRadius: 2, marginRight: 2}}><Refresh fontSize='large' /></IconButton>
        <TextField size='small' id="standard-basic" label="Chercher un patient" variant="outlined" onChange={handleChange} />
        <IconButton disabled={isLoadingCsv} onClick={async () => {
          try {
            setIsLoadingCsv(true);
            const data = await getAnalyseKitCsv().unwrap();
            generateXLSX(data, 'Données patients');
          } catch (error) {
            setIsError(true);
          } finally {
            setIsLoadingCsv(false);
          }
        }} size='small' sx={{background: 'white', borderRadius: 2, marginRight: 2, marginX: 2}}>
          <Typography>Télécharger les données</Typography><DownloadIcon fontSize='large' />
        </IconButton>
        <Box sx={{width: 30}}>{isLoadingCsv && <CircularProgress size={30} />}</Box>
        <IconButton disabled={isLoadingPayement} onClick={async () => {
          try {
            setIsLoadingPayement(true);
            const data = await getPatientToPayed().unwrap();
            generateXLSX(data, 'Données patients pour le paiement');
          } catch (error) {
            setIsError(true);
          } finally {
            setIsLoadingPayement(false);
          }
        }} size='small' sx={{background: 'white', borderRadius: 2, marginRight: 2, marginX: 2}}>
          <Typography>Télécharger les paiements</Typography><EuroIcon fontSize='large' />
        </IconButton>
        <Box sx={{width: 30}}>{isLoadingPayement && <CircularProgress size={30} />}</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          boxShadow: 1,
          borderRadius: 5,
          paddingBottom: 2,
        }}
      ><DataGrid
          rows={globalPatients.length > 0 ? (filter !== '' ? sortedPatients.filter(patient => patient.id.includes(filter)) : sortedPatients) : []}
          loading={isLoading}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          getRowClassName={(params) => params.row.report ? "{backgroundColor: 'red'}" : "{backgroundColor: 'blue'}"}
          sx={{
            height: globalPatients.length === 0 ? 400 : 'auto',
            border: 'none'
          }}
        />
      </Box>
      <PatientsExpiredDashboard />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{flex: 1, display: 'flex', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
            <CheckCircle style={{fontSize: 130, color: 'white', margin: 30}} />
          </Box>
          <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
            <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
      Un nouveau mot de passe a été créé avec succès
            </Typography>
            <Typography id="modal-modal-description" sx={{ marginY: 1 }}>
      Veuillez copier le code et le sauvegarder
            </Typography>
            <Box>
              <TextField disabled value={id} id="identifiant" label="identifiant"  variant="outlined" />
              <CopyButton textToCopy={id} />
            </Box>
            <Typography id="modal-modal-description" sx={{ marginY: 1 }}>
      Veuillez copier le code et le sauvegarder
            </Typography>
            <Box>
              <TextField disabled value={password} id="password" label="mot de passe"  variant="outlined" />
              <CopyButton textToCopy={password} />
            </Box>
            <Button onClick={() => {
              handleCloseModal();
            }} color='error'>Fermer</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openOptionModal}
        onClose={handleCloseOptionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 2}}>
            <WarningIcon color='error' sx={{ fontSize: 40 }} />
            <Typography color='error'>Attention, cette action a des effets irréversibles</Typography>
            <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
            Êtes-vous sûr ?
            </Typography>
            <Box>
              <Button onClick={() => {
                optionModalFunction && optionModalFunction();
                handleCloseOptionModal();
              }} color='info'>oui</Button>
              <Button onClick={() => {
                handleCloseOptionModal();
              }} color='error'>non</Button>
            </Box>
              
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openReportModal}
        onClose={handleCloseReportModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: 3}}>
            <Typography id="modal-modal-title" sx={{textAlign: 'center'}} variant="h6" component="h2">
      Que voulez vous signaler ?
            </Typography>
            <TextField
              fullWidth
              sx={{paddingTop: 3}}
              multiline
              rows={5}
              onChange={handleChangeReport}
              value={report}
            />
            <Button variant='contained' sx={{marginTop: 3}} onClick={handleReportPatient}>Envoyer</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
