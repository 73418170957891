import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SurveyInterface } from '../../types/types';
import { SurveyState } from '../type';

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    surveys: [],
  } as SurveyState,
  reducers: {
    setSurvey(state, action: PayloadAction<SurveyInterface[]>) {
      state.surveys = action.payload;
    },
    logoutSurvey(state) {
      state.surveys = [];
    }
  },
});

export const { setSurvey, logoutSurvey } = surveySlice.actions;

export const surveyReducer = surveySlice.reducer;
