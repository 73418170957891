import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdverseEvent } from '../../types/types';
import { AdverseEventState } from '../type';

const adverseSlice = createSlice({
  name: 'adverseSlice',
  initialState: {
    adverseEvent: [],
  } as AdverseEventState,
  reducers: {
    setAdverseEvent(state, action: PayloadAction<AdverseEvent[]>) {
      state.adverseEvent = action.payload;
    },
    logoutAdverseEvent(state) {
      state.adverseEvent = [];
    }
  },
});

export const { setAdverseEvent, logoutAdverseEvent } = adverseSlice.actions;

export const adverseEventReducer = adverseSlice.reducer;
