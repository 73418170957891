import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Answer } from '../../types/types';
import { AnswerState } from '../type';

const answerSlice = createSlice({
  name: 'answer',
  initialState: {
    answers: [],
  } as AnswerState,
  reducers: {
    setAnswer(state, action: PayloadAction<Answer[]>) {
      state.answers = action.payload;
    },
    updateAnswer(state, action: PayloadAction<Answer>) {
      const existingAnswer = state.answers.find(
        (element) => element.question_id === action.payload.question_id
      );
      const updatedAnswers = existingAnswer
        ? state.answers.map((element) =>
          element.question_id === action.payload.question_id
            ? {
              question_id: action.payload.question_id,
              answer: action.payload.answer,
            }
            : element
        )
        : [
          ...state.answers,
          {
            question_id: action.payload.question_id,
            answer: action.payload.answer,
          },
        ];
      state.answers = updatedAnswers;
    },
    logoutAnswer(state) {
      state.answers = [];
    }
  },
});

export const { setAnswer, updateAnswer, logoutAnswer } = answerSlice.actions;

export const answerReducer = answerSlice.reducer;
