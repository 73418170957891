import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Patient, PatientGroup, PatientStatus } from '../../types/types';
import { PatientState } from '../type';

const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    patients: [],
    patient: {
      id: '',
      patient_status: PatientStatus.Finished,
      phase: '',
      step: '',
      site: '',
      patient_group: PatientGroup.ClinicallyAtRisk,
      admin_id: '',
      timestamp_creation: 0,
      rank: 0,
      birthdate: '',
      bmi: 0,
      sex: '',
      note: '',
      report_problem: '',
      inclusion_timestamp: 0,
      expiration_timestamp: 0,
      patient_part_finished: 'false',
      r24_validate: 'false',
      sample_validate: 'false',
      anti_lipid: null,
      antihypertensives: null,
      anti_obesogens: null,
      menopause_status: null,
      diet: null,
      nursing: null,
      transferred: false,
      report: false,
      biological_file_name: [],
      "r24-1": "",
      "r24-2": "",
      "r24-3": "",
      finished_timestamp: undefined,
    },
  } as PatientState,
  reducers: {
    setPatients(state, action: PayloadAction<Patient[]>) {
      state.patients = action.payload;
    },
    setPatient(state, action: PayloadAction<Patient>) {
      state.patient = action.payload;
    },
    updatePatient(state, action: PayloadAction<Patient>) {
      const existingData = state.patients.find(
        (element) => element.id === action.payload.id
      );
      const updatedDatas = existingData
        ? state.patients.map((element) =>
          element.id === action.payload.id
            ? {
              site: action.payload.site,
              patient_status: action.payload.patient_status,
              phase: action.payload.phase,
              step: action.payload.step,
              admin_id: action.payload.admin_id,
              id: action.payload.id,
              timestamp_creation: action.payload.timestamp_creation,
              patient_group: action.payload.patient_group,
              rank: action.payload.rank,
              sex: action.payload.sex,
              birthdate: action.payload.birthdate,
              bmi: action.payload.bmi,
              note: action.payload.note,
              report: action.payload.report,
              inclusion_timestamp: action.payload.inclusion_timestamp,
              expiration_timestamp: action.payload.expiration_timestamp,
              patient_part_finished: action.payload.patient_part_finished,
              r24_validate: action.payload.r24_validate,
              sample_validate: action.payload.sample_validate,
              anti_lipid: action.payload.anti_lipid,
              antihypertensives: action.payload.antihypertensives,
              anti_obesogens: action.payload.anti_obesogens,
              menopause_status: action.payload.menopause_status,
              diet: action.payload.diet,
              nursing: action.payload.nursing,
              transferred: action.payload.transferred,
              report_problem: action.payload.report_problem,
              biological_file_name: action.payload.biological_file_name,
              "r24-1": action.payload["r24-1"],
              "r24-2": action.payload["r24-2"],
              "r24-3": action.payload["r24-3"],
              finished_timestamp: action.payload.finished_timestamp
            }
            : element
        )
        : [
          ...state.patients,
          {
            site: action.payload.site,
            patient_status: action.payload.patient_status,
            phase: action.payload.phase,
            step: action.payload.step,
            admin_id: action.payload.admin_id,
            id: action.payload.id,
            timestamp_creation: action.payload.timestamp_creation,
            patient_group: action.payload.patient_group,
            rank: action.payload.rank,
            sex: action.payload.sex,
            birthdate: action.payload.birthdate,
            bmi: action.payload.bmi,
            note: action.payload.note,
            report: action.payload.report,
            inclusion_timestamp: action.payload.inclusion_timestamp,
            expiration_timestamp: action.payload.expiration_timestamp,
            patient_part_finished: action.payload.patient_part_finished,
            r24_validate: action.payload.r24_validate,
            sample_validate: action.payload.sample_validate,
            anti_lipid: action.payload.anti_lipid,
            antihypertensives: action.payload.antihypertensives,
            anti_obesogens: action.payload.anti_obesogens,
            menopause_status: action.payload.menopause_status,
            diet: action.payload.diet,
            nursing: action.payload.nursing,
            transferred: action.payload.transferred,
            report_problem: action.payload.report_problem,
            biological_file_name: action.payload.biological_file_name,
            "r24-1": action.payload["r24-1"],
            "r24-2": action.payload["r24-2"],
            "r24-3": action.payload["r24-3"],
            finished_timestamp: action.payload.finished_timestamp
          },
        ];
      state.patients = updatedDatas;
    },
    logoutPatient(state) {
      state.patients = [];
      state.patient = {
        id: '',
        patient_status: PatientStatus.Finished,
        phase: '',
        step: '',
        site: '',
        patient_group: PatientGroup.ClinicallyAtRisk,
        admin_id: '',
        timestamp_creation: 0,
        rank: 0,
        birthdate: '',
        bmi: 0,
        sex: '',
        note: '',
        report_problem: '',
        inclusion_timestamp: 0,
        expiration_timestamp: 0,
        patient_part_finished: 'false',
        r24_validate: 'false',
        sample_validate: 'false',
        anti_lipid: null,
        antihypertensives: null,
        anti_obesogens: null,
        menopause_status: null,
        diet: null,
        nursing: null,
        transferred: false,
        report: false,
        biological_file_name: [],
        "r24-1": "",
        "r24-2": "",
        "r24-3": "",
        finished_timestamp: undefined,
      }
    }
  },
});

export const { setPatient, setPatients, updatePatient, logoutPatient } = patientSlice.actions;

export const patientReducer = patientSlice.reducer;
